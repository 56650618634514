//@flow

import React, { Component } from 'react'
import { graphql } from 'gatsby'

import styled from 'styled-components'
import Layout from '../layouts'
import SEO from '../components/SEO.js'
import config from '../../config'

import { PostText, BaseInput, BaseButton, Title } from '../components/Common.js'

export default class Contact extends Component {
  state = {
    showThanks: false,
  }

  componentDidMount() {
    this.setState({
      showThanks: window.location.hash === '#thanks' ? true : false,
    })
  }

  render() {
    return (
      <Layout title={config.siteTitle + ' | Contact'}>
        <Title
          css={{
            color: '#78abbe',
            fontSize: '3rem',
            lineHeight: '1.5em',
            textAlign: 'center',
          }}
          style={{ marginTop: 40 }}
        >
          Hey – thanks stoping by
        </Title>

        <PostText css={{ textAlign: 'center' }}>
          <p>Need to chat?</p>
          <p>Have any question or comments about freelancing? </p>
          <p>Wanna start a conversation about something?</p>
          <p>I'm an open book!</p>
        </PostText>

        {this.state.showThanks && (
          <Success>
            <p style={{ margin: 0 }}>Thanks! You will be contacted shortly!</p>
          </Success>
        )}

        <ContactForm
          method="POST"
          action="http://formspree.io/darryl.dex@gmail.com"
        >
          <ContactInput type="name" name="name" placeholder="Name" />
          <ContactInput type="email" name="email" placeholder="Your email" />

          <MessageArea name="message" placeholder="Your message" rows="5" />

          <input type="hidden" name="_next" value="/contact#thanks" />

          <BaseButton style={{ height: 50, padding: 10 }} type="submit">
            Send
          </BaseButton>
        </ContactForm>

        <SEO />
      </Layout>
    )
  }
}

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: auto;
`

const ContactInput = BaseInput.extend`
  margin-bottom: 15px;
  margin-right: 0;
  padding: 10px;
`

const TextArea = BaseInput.withComponent('textarea')

const MessageArea = TextArea.extend`
  margin-bottom: 15px;
  margin-right: 0;
  height: 400px;
  resize: none;
  padding: 10px;
`

const Success = styled.div`
  opacity: 1;
  padding: 20px;
  background: #c8e4bd;
  margin-bottom: 20px;
  border: 1px solid #8aaa7d;
`

// Set here the ID of the home page.
export const pageQuery = graphql`
  query contactQuery {
    allWordpressPost {
      edges {
        node {
          id
          title
          excerpt
          slug
          date(formatString: "MMMM DD, YYYY")
          better_featured_image {
            media_details {
              sizes {
                large {
                  source_url
                }
              }
            }
          }
        }
      }
    }
  }
`
